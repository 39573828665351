import React from "react"
import theme from "theme"
import { Theme, Text, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contacts"} />
      <Helmet>
        <title>Оренда яхт Umartislin</title>
        <meta
          name={"description"}
          content={"Втілення мрій у реальність"}
        />
        <meta property={"og:title"} content={"Оренда яхт Umartislin"} />
        <meta
          property={"og:description"}
          content={"Втілення мрій у реальність"}
        />
        <meta
          property={"og:image"}
          content={"https://umartislin.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://umartislin.com/img/035901.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://umartislin.com/img/035901.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://umartislin.com/img/035901.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://umartislin.com/img/035901.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://umartislin.com/img/035901.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://umartislin.com/img/035901.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0" sm-padding="40px 0">
        <Override slot="SectionContent" align-items="center" />
        <Text
          as="h2"
          font="--headline1"
          md-font="--headline2"
          margin="20px 0 0 0"
        >
          Зв'яжіться з нами
        </Text>
        <Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
          Зв’яжіться з нами, щоб отримати детальну інформацію, запити на бронювання або будь-які особливі вимоги, які у вас можуть виникнути. Ми прагнемо оперативно та вичерпно відповідати на всі звернення.
          <br />
          <br />
          вулиця Прирічна, 17/19 лодочная станция Лагуна, Київ
          <br />
          +38(067)209-84-90
          <br />
          support@umartislin.com
          <br />
          <br />
          <br />
          <br />
          Оренда яхт Umartislin
          <br />
          Втілення ваших мрій у реальність

        </Text>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
